export default {
    M00_long_name: 'Uitrustingsinspectie',
    M00_requirements: '',
    M00_description: '',
    M00_1_desc:
        '(Voor de wedstrijd) De robot en alle uitrusting past volledig in één startgebied en zit onder een hoogtelimiet van 305mm',
    M01_long_name: 'KORAALKWEKERIJ',
    M01_requirements: 'Niet rakenregel is van toepassing',
    M01_description: '',
    M01_1_desc: 'De koraalboom hangt aan de koraalboomsteun',
    M01_2_desc: 'De onderkant van de koraalboom zit in zijn houder',
    M01_3_desc: 'De koraalknoppen zijn omhoog gedraaid',
    M02_long_name: 'HAAI',
    M02_requirements: '',
    M02_description: '',
    M02_1_desc: 'De haai raakt de grot niet meer',
    M02_2_desc: 'De haai raakt de mat en is ten minste gedeeltelijk in zijn eigen leefomgeving',
    M03_long_name: 'KORAALRIF',
    M03_requirements: '',
    M03_description: '',
    M03_1_desc: 'Het koraalrif is omhoog gekanteld en raakt de mat niet',
    M03_2_desc: 'Het aantal koraal-onderdelen dat rechtop staat, buiten het thuisgebied en de mat raakt',
    M04_long_name: 'DIEPZEEDUIKER',
    M04_requirements: '',
    M04_description: '',
    M04_1_desc: 'De diepzeeduiker raakt de koraalkwekerij niet meer',
    M04_2_desc: 'De diepzeeduiker hangt aan de koraalboomsteun',
    M05_long_name: 'HENGELVIS',
    M05_requirements: '',
    M05_description: '',
    M05_1_desc: 'De hengelvis is verankerd binnen het scheepswrak',
    M06_long_name: 'HIJS DE MAST',
    M06_requirements: '',
    M06_description: '',
    M06_1_desc: 'De mast van het scheepswrak is volledig opgehesen',
    M07_long_name: 'SCHAT VAN DE KRAKEN',
    M07_requirements: '',
    M07_description: '',
    M07_1_desc: 'De schatkist is volledig buiten het nest van de Kraken',
    M08_long_name: 'KUNSTMATIG LEEFGEBIED',
    M08_requirements: '',
    M08_description: '',
    M08_1_desc: 'Aantal segmenten van kunstmatige habitatstapels volledig plat en rechtop:',
    M09_long_name: 'ONVERWACHTE ONTMOETING',
    M09_requirements: '',
    M09_description: '',
    M09_1_desc: 'Het onbekende wezen is losgelaten:',
    M09_2_desc: 'Het onbekende wezen is ten minste gedeeltelijk in de koude bron:',
    M10_long_name: 'STUUR DE ONDERZEEER',
    M10_requirements: '',
    M10_description: '',
    M10_1_desc: 'De gele vlag van het team is omlaag:',
    M10_2_desc: 'De onderzeeër is duidelijk dichter bij het tegenoverliggende veld:',
    M11_long_name: 'SONAR ONTDEKKING',
    M11_requirements: '',
    M11_description: '',
    M11_1_desc: 'Er is 1 walvis onthuld',
    M11_2_desc: 'De 2e walvis is onthuld',
    M12_long_name: 'VOER DE WALVIS',
    M12_requirements: '',
    M12_description: '',
    M12_1_desc: 'Het aantal krill dat tenminste gedeeltelijk in de mond van de walvis is.',
    M13_long_name: 'VERANDER DE VAARROUTES',
    M13_requirements: '',
    M13_description: '',
    M13_1_desc: 'Het schip bevindt zich in de nieuwe vaarroute en raakt de mat:',
    M14_long_name: 'MONSTERVERZAMELING',
    M14_requirements: '',
    M14_description: '',
    M14_1_desc: 'Het watermonster is volledig buiten het watermonstergebied:',
    M14_2_desc: 'Het zeebodemmonster raakt de zeebodem niet meer:',
    M14_3_desc: 'Het planktonmonster raakt het kelpwoud niet meer:',
    M14_4_desc: 'Het aantal delen van de drietand die het scheepswrak raken:',
    M15_long_name: 'ONDERZOEKSSCHIP',
    M15_requirements: '',
    M15_description: '',
    M15_1_desc:
        'Aantal monsters, drietand(onderdelen), of schatkisten ten minste gedeeltelijk in het' +
        ' onderzoeksschip opberggedeelte:',
    M15_2_desc: 'De vergrendeling van de haven is ten minste gedeeltelijk in de lus van het schip',
};
