export default {
    M00_long_name: 'INSPECTION D’ÉQUIPEMENT',
    M00_requirements: '',
    M00_description: '',
    M00_1_desc: "Tout l'équipement de l'équipe tient dans une seule zone de lancement et ne dépasse pas 305 mm :",
    M01_long_name: 'PÉPINIÈRE DE CORAUX',
    M01_requirements: 'Contrainte d’équipement',
    M01_description: '',
    M01_1_desc: 'L’arbre à corail est suspendu à son support',
    M01_2_desc: 'Le bas de l’arbre à corail est dans son support',
    M01_3_desc: 'Les bourgeons sont retournés',
    M02_long_name: 'SUIVI DU REQUIN',
    M02_requirements: '',
    M02_description: '',
    M02_1_desc: 'Le requin ne touche plus la grotte',
    M02_2_desc: 'Le requin touche le tapis et se trouve au moins partiellement dans son habitat',
    M03_long_name: 'RÉCIF CORALLIEN',
    M03_requirements: '',
    M03_description: '',
    M03_1_desc: 'Le récif de corail est retourné, sans toucher le tapis',
    M03_2_desc: 'Un fragment de récif est debout, à l’extérieur de la zone de départ, et touche le tapis',
    M04_long_name: 'PLONGEUR SOUS-MARIN',
    M04_requirements: '',
    M04_description: '',
    M04_1_desc: 'Le plongeur ne touche plus la pépinière de corail',
    M04_2_desc: 'Le plongeur est supendu sur le support du récif de corail',
    M05_long_name: 'POISSON PÊCHEUR',
    M05_requirements: '',
    M05_description: '',
    M05_1_desc: 'Le poisson-pêcheur est repoussé dans l’épave',
    M06_long_name: 'RELEVER LE MÂT',
    M06_requirements: '',
    M06_description: '',
    M06_1_desc: 'Le mât de l’épave est complètement relevé',
    M07_long_name: 'TRÉSOR DU KRAKEN',
    M07_requirements: '',
    M07_description: '',
    M07_1_desc: 'Le coffre au trésor est complètement en dehors de l’antre du Kraken',
    M08_long_name: 'HABITAT ARTIFICIEL',
    M08_requirements: '',
    M08_description: '',
    M08_1_desc: 'Un segment de paquet d’habitat artificiel est positionné dans le bon sens',
    M09_long_name: 'RENCONTRE INATTENDUE',
    M09_requirements: '',
    M09_description: '',
    M09_1_desc: 'La créature inconnue est libérée',
    M09_2_desc: 'La créature inconnue est au moins partiellement dans la zone de suintement froid',
    M10_long_name: 'ENVOI DU SUBMERSIBLE',
    M10_requirements: '',
    M10_description: '',
    M10_1_desc: 'Le drapeau jaune de votre équipe est abaissé',
    M10_2_desc: 'Le submersible est clairement plus proche du terrain adverse',
    M11_long_name: 'DÉCOUVERTE PAR SONAR',
    M11_requirements: '',
    M11_description: '',
    M11_1_desc: 'Une baleine est détectée',
    M11_2_desc: 'Les deux baleines sont détectées',
    M12_long_name: 'NOURRIR LA BALEINE',
    M12_requirements: '',
    M12_description: '',
    M12_1_desc: 'Nombre de krill au moins partiellement dans la bouche de la baleine',
    M13_long_name: 'CHANGER DE VOIES DE NAVIGATION',
    M13_requirements: '',
    M13_description: '',
    M13_1_desc: 'Le navire se trouve dans le nouveau couloir de navigation et touche le tapis',
    M14_long_name: 'COLLECTE D’ÉCHANTILLONS',
    M14_requirements: '',
    M14_description: '',
    M14_1_desc: 'L’échantillon d’eau est complètement en dehors de sa zone d’échantillonnage',
    M14_2_desc: 'L’échantillon de fond marin ne touche plus celui-ci',
    M14_3_desc: 'L’échantillon de plancton ne touche plus la forêt de varech',
    M14_4_desc: "Le nombre de pièces de trident ne touche plus l'épave",
    M15_long_name: 'NAVIRE DE RECHERCHE',
    M15_requirements: '',
    M15_description: '',
    M15_1_desc:
        "Nombre d'échantillons, de trident ou de coffre au trésor au moins partiellement dans  la zone de chargement" +
        'du navire de recherche:',
    M15_2_desc: 'Le loquet du port se trouve au moins en partie dans la boucle du navire de recherche',
};
